/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import walletService from 'service/wallet';
import providerService from 'service/provider';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

interface AddSettelementProps {
  setOpenSettelement: (show: boolean) => void;
  selectedProviderId?: any;
  selectedTableData?: any;
  refetch?: any;
}

const AddSettelement = ({
  setOpenSettelement,
  selectedProviderId,
  selectedTableData,
  refetch
}: AddSettelementProps) => {
  const schema = yup.object().shape({
    currency_id: yup.string().required('Обязательное поле'),
    wallet: yup.string().required('Обязательное поле'),
    balance: yup.string(),
    remainder: yup.string(),
    note: yup.string(),
    debit: yup.string().required('Обязательное поле')
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const [loading, setLoading] = useState(false);
  const [walletOption, setWalletOption] = useState([
    { label: '', value: '', total_balance: '' }
  ]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>('');
  const [disabledFields, setDisabledFields] = useState(false);

  //GET Wallet to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 20 })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.ru
        }));
        setCurrencyOption(options);
      });
  });

  //GET Wallet to options
  useQuery(
    ['GET_WALLET', selectedCurrencyId],
    async () => {
      if (selectedCurrencyId !== '') {
        await walletService
          .getList({
            offset: 0,
            limit: 20,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => {
            const options = res?.wallets?.map((option: any) => ({
              value: option.id,
              label: option.name,
              total_balance: option?.total_balance
            }));
            setWalletOption(options);
          });
      }
    },
    { enabled: selectedCurrencyId !== '' }
  );

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedProviderId !== '') return createData(data);
  };

  const createData = (data: any) => {
    if (+data?.balance === 0 || +data.balance < +data?.debit) {
      setLoading(false);
      dispatch(
        showAlert({
          title: 'Не хватает средство!'
        })
      );
      return;
    }

    if (!cashboxId) {
      dispatch(showAlert({ title: 'Смена еще не открыта' }));
      return;
    }

    const createdData = {
      cashbox_id: cashboxId,
      amount_credit: +selectedTableData.credit,
      amount_debit: +data?.debit,
      creditor_id: selectedTableData?.creditor_id,
      debit_credit_id: selectedProviderId,
      debitor_id: selectedTableData?.debitor_id,
      note: data?.note,
      wallet_id: data?.wallet
    };

    if (+data?.balance > 0 && +data.balance > +data?.debit) {
      setLoading(true);

      providerService
        .createProviderSettlement(createdData)
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Урегулирование успешно добавлен!',
                type: 'success'
              })
            );
            refetch();
            setOpenSettelement(false);
            setLoading(false);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (disabledFields && inputRef.current) {
      inputRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current, disabledFields]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="currency_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      ref={inputRef}
                      onChange={(e: any) => {
                        const selectedOption = currencyOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedCurrencyId(selectedOption.value);
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.currency_id}
                    >
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Валюта</label>
                    {errors?.currency_id?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.currency_id?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="wallet"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = walletOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue(
                            'balance',
                            selectedOption.total_balance ?? 0
                          );
                          setValue(
                            'remainder',
                            selectedTableData ? selectedTableData.remainder : 0
                          );
                          setDisabledFields(true);
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.wallet}
                    >
                      <option className="d-none" value=""></option>
                      {selectedCurrencyId !== ''
                        ? walletOption?.map((option: any) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        : []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Кошелек</label>
                    {errors?.wallet?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.wallet?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={disabledFields}
                {...register('balance')}
                type="text"
                placeholder="Баланс"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Баланс</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="debit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={disabledFields === true ? inputRef : undefined}
                    type="text"
                    placeholder="Дебет"
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.debit}
                  />
                  <label htmlFor="floatingInputCustom">Дебет</label>
                  {errors.debit && (
                    <Form.Control.Feedback type="invalid">
                      {errors.debit.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={disabledFields}
                {...register('remainder')}
                type="text"
                placeholder="Остаток"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Остаток</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('note')}
                type="text"
                placeholder="Примечание"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Примечание</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          Сохранить
        </Button>
      </Form>
    </>
  );
};

export default AddSettelement;
