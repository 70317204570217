/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { OutgoingReturning } from 'data/returning';
import returnOutService from 'service/returnOut';
import legalEntitySettingsService from 'service/legalEntitySettings';

export const outgoingTableColumns: ColumnDef<OutgoingReturning>[] = [
  {
    accessorKey: 'orders',
    header: '№ заявки',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'sender',
    header: 'Отправитель',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'recipient',
    header: 'Получатель',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояния',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'create_date',
    header: 'Дата создания',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface OutgoingReturningTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionStatusId?: any;
}

const OutgoingReturningTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionStatusId
}: OutgoingReturningTableProps) => {
  const navigate = useNavigate();

  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  const navigateTo = (row: any) => {
    navigate(
      `/returning/outgoing/returning-outgoing-result/${row?.original?.guid}`
    );
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get Return
  const { data, refetch } = useQuery(
    ['GET_RETURN_OUT_MAIN'],
    async () => {
      const adjustedDateTo =
        dateTo === dateFrom ? dateFrom + 86400000 : dateTo + 86400000;
      const res = await returnOutService
        .getList({
          offset: 0,
          limit: 100,
          status_id: optionStatusId ? optionStatusId : undefined,
          recipient_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.returns);
      return res;
    },
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!optionSenderId || !!optionStatusId
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const returnData: OutgoingReturning[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          orders: items?.return_number,
          status: items?.status?.name?.ru,
          sender: legalEntityName,
          recipient: items?.legal_entity?.name,
          /* prettier-ignore */
          create_date: formatDateToTimeZone(items?.date_create, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const table = useAdvanceTable({
    data: returnData,
    columns: outgoingTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionStatusId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default OutgoingReturningTable;
