/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Form } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatherIcon from 'feather-icons-react';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/base/Button';
import productSearchService from 'service/productSearch';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import scalesService from 'service/scales';
import { showAlert } from 'store/alert/alert.thunk';
import { pageToOffset } from 'utils/pageToOffset';
import unitService from 'service/units';

export type SearchType = {
  product_name: string;
  barcode: string;
  units: string;
  price: string;
  guid: string;
};

const AddProductScalesTable = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState<any>([]);
  const [getBarcode, setGetBarcode] = useState<any>([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [unitsOption, setUnitsOption] = useState<any>([]);
  const [selectedMeasureUnitId, setSelectedMeasureUnitId] = useState<any>('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const dispatch: Dispatch<any> = useDispatch();

  //GET UNITS to options
  useQuery(['GET_UNITS'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option?.id,
        label: option?.name?.ru
      }));
      setUnitsOption(options);
    });
  });

  const { data, isFetching, isLoading } = useQuery(
    [
      'GET_PRODUCTS_SCALES',
      searchValue,
      getBarcode,
      currentPage,
      selectedMeasureUnitId
    ],
    async () => {
      const res = await productSearchService
        .getListProduct({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          name: searchValue,
          measure_unit_id: selectedMeasureUnitId,
          barcode: getBarcode
        })
        .then((res: any) => {
          return res;
        });

      return res;
    },
    {
      enabled:
        searchValue.length > 2 ||
        !!getBarcode ||
        !!currentPage ||
        !!selectedMeasureUnitId
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  useEffect(() => {
    if (data?.products) {
      const resData =
        data?.products.map((items: any) => {
          return {
            product_name:
              data?.products.is_product === true
                ? items?.name
                : items?.nomenclature?.name,
            barcode:
              data?.products.is_product === true
                ? items?.barcode
                : items?.nomenclature?.barcode,
            units: items?.nomenclature?.measure_unit?.name?.ru,
            price: items?.price ?? 0,
            scale_code: items?.scale_code ?? 0,
            guid: items?.id
          };
        }) ?? [];

      if (resData.length > 0) {
        setSearchedData(resData);
        inputRefs.current = resData.map(
          (_: any, i: any) => inputRefs.current[i] || null
        );
      }
    }
  }, [data]);

  const searchTableColumns: ColumnDef<SearchType>[] = [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: 'Цена продажи',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'scale_code',
      header: 'Штрих-код весовой',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';
        const updatedProductId = rowData?.row?.original?.guid;
        const [value, setValue] = useState(amount);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            setValue(newValue);
          }
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const newValue = +value;

          const updatedItems = searchedData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: newValue };
            }
            return item;
          });

          setSearchedData(updatedItems);
          setValue(newValue); // Ensure the displayed value is updated correctly

          // Update condition to allow 0 as a valid value
          if (
            updatedProductId !== '' &&
            newValue !== undefined &&
            newValue !== null
          ) {
            scalesService
              .updateScales(updatedProductId, {
                scale_code: newValue // No need for +newValue, as it's already converted
              })
              .then(res => {
                if (res) {
                  dispatch(
                    showAlert({
                      title: 'Штрих-код весовой успешно изменён!',
                      type: 'success'
                    })
                  );
                }
              })
              .catch(error => {
                // Handle the error: set the value to 0 in updatedItems
                const updatedErrorItems = updatedItems.map(
                  (item: any, i: any) => {
                    if (i === +index) {
                      return { ...item, scale_code: 0 };
                    }
                    return item;
                  }
                );

                setSearchedData(updatedErrorItems); // Update the state with the modified data

                if (error?.response?.status === 404) {
                  dispatch(
                    showAlert({
                      title: 'Штрих-код весовой существует!'
                    })
                  );
                }
              });
          }
        };

        const handleKeyDown = (e: any, rowIndex: any) => {
          if (e.key === 'Tab') {
            const { name, value: inputValue } = e.target as HTMLInputElement;
            const newValue = +inputValue;

            // Updating product data and navigating between rows
            const updatedItems = searchedData.map((item: any, i: any) => {
              if (i === +rowIndex) {
                return { ...item, [name]: newValue };
              }
              return item;
            });

            setSearchedData(updatedItems);

            if (e.key === 'Tab') {
              const currentRowIndex = rowData.row.index;
              const nextRowIndex = currentRowIndex + 1;

              console.log('nextRowIndex', nextRowIndex);

              if (nextRowIndex < searchedData.length) {
                console.log('next', inputRefs.current[nextRowIndex]?.focus());
                setTimeout(() => {
                  inputRefs.current[nextRowIndex]?.focus();
                }, 300);
              } else {
                // If it's the last item, move focus to the first item in the next column
                setTimeout(() => {
                  inputRefs.current[nextRowIndex]?.focus();
                }, 300);
              }

              // const nextRowIndex = rowIndex + 1;
              // if (nextRowIndex < searchedData.length) {
              //   setTimeout(() => {
              //     inputRefs.current[nextRowIndex]?.focus();
              //   }, 0); // Move focus to the next input after the state update
              // }
            }
          }
        };

        return (
          <div style={{ width: '150px' }}>
            <Form.Group>
              <Form.Control
                type="text"
                name="scale_code"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onKeyDown={e => handleKeyDown(e, rowData.row?.id)} // Adjust the keydown handler to include rowIndex
                onFocus={e => e.target.select()}
                ref={(el: any) =>
                  (inputRefs.current[Number(rowData.row?.id)] = el)
                }
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchedData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex gap-2 mb-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Выведите товар"
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Выведите товар
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Выведите штрих-код"
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Выведите штрих-код
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Form.Floating>
              <Form.Select
                value={selectedMeasureUnitId}
                onChange={(e: any) => {
                  const selectedOption = unitsOption.find(
                    (option: any) => option.value === e.target.value
                  );

                  if (selectedOption) {
                    setSelectedMeasureUnitId(selectedOption?.value);
                  }
                }}
              >
                <option className="d-none" value=""></option>
                {unitsOption?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">Единица измерения</label>
              {selectedMeasureUnitId !== '' && (
                <Button
                  type="button"
                  variant="link"
                  style={{
                    position: 'absolute',
                    right: '5%',
                    top: '15%',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setSelectedMeasureUnitId('');
                  }}
                >
                  <FeatherIcon icon="delete" size={16} />
                </Button>
              )}
            </Form.Floating>
          </div>
        </Form.Group>
      </div>

      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        isLoading={loading}
      />
      <AdvanceTableCPagination
        count={pageCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </AdvanceTableProvider>
  );
};

export default AddProductScalesTable;
