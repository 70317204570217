/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';

import useAdvanceTable from 'hooks/useAdvanceTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import productSearchService from 'service/productSearch';
import Button from 'components/base/Button';
import { pageToOffset } from 'utils/pageToOffset';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';

export type SearchProduct = {
  name: string;
  variation: string;
  barcode: string;
  original_barcode: string;
  unit: string;
  quantity: string;
  nomenclature_id: string;
  currency_id: string;
  price_in: string;
  guid: string;
  measure_unit_kind_id: string;
};

interface SearchProductInventoryProps {
  setOpenSearch: (show: boolean) => void;
  setDisabledField: (show: boolean) => void;
  setFocusToInput: (show: boolean) => void;
  reset?: any;
  setSearchedValue?: any;
  tableData?: any;
  warehouseId?: any;
}

const SearchProductInventory = ({
  setOpenSearch,
  setDisabledField,
  setFocusToInput,
  reset,
  setSearchedValue,
  tableData,
  warehouseId
}: SearchProductInventoryProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const inputSearchRef = useRef<HTMLInputElement | any>();

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_PRODUCT', searchValue, currentPage],
    () => {
      if (warehouseId) {
        const res = productSearchService
          .getListProduct({
            offset: pageToOffset(currentPage, 10),
            limit: 10,
            warehouse_id: warehouseId,
            name: searchValue
          })
          .then((res: any) => {
            setPageCount(Math.ceil(res?.count / 10));
            return res?.products;
          });
        return res;
      }
    },
    { enabled: !!currentPage || !searchValue || searchValue?.length > 2 }
  );

  const loadingData = isLoading || isFetching;

  const generateData: SearchProduct[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          variation: items?.nomenclature?.is_variation ? 'ДА' : 'НЕТ',
          barcode: items?.barcode,
          original_barcode: items?.nomenclature?.barcode_original,
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          quantity: items?.total_quantity,
          nomenclature_id: items?.nomenclature_id,
          currency_id: items?.currency_id,
          price: items?.price ?? 0,
          price_in: items?.price_in ?? 0,
          guid: items?.id,
          measure_unit_kind: items?.nomenclature?.measure_unit_kind_id
        };
      }) ?? []
    );
  }, [data, searchValue]);

  const searchProductTableColumns: ColumnDef<SearchProduct>[] = [
    {
      id: 'action',
      cell: rowData => {
        const createdData = {
          product_name: rowData?.row?.original?.name,
          product_id: rowData?.row?.original?.guid,
          scan: rowData?.row?.original?.barcode,
          current_inventory_balance:
            tableData[rowData?.row?.original?.barcode]?.quantity_fact ?? 0,
          current_balance: rowData?.row?.original?.quantity,
          nomenclature_id: rowData?.row?.original?.nomenclature_id,
          currency_id: rowData?.row?.original?.currency_id,
          price_in: rowData?.row?.original?.price_in,
          measure_unit_kind: rowData?.row?.original?.measure_unit_kind_id
        };

        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                reset(createdData);
                setSearchedValue(`${rowData?.row?.original?.barcode}`);
                setDisabledField(true);
                setOpenSearch(false);
                setFocusToInput(true);
              }}
            >
              <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: 'Вариация',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: 'Штрих-код оригинал',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: 'Цена продажи',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: searchProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  useEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }, [inputSearchRef]);
  return (
    <div>
      <div className="mb-4">
        <SearchBox
          placeholder="Выведите наименования товара для поиска"
          onChange={e => setSearchValue(e?.target?.value)}
          ref={inputSearchRef}
        />
      </div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          isLoading={loadingData}
        />
        <AdvanceTableCPagination
          count={pageCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </AdvanceTableProvider>
    </div>
  );
};

export default SearchProductInventory;
