/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import providerService from 'service/provider';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
};

export const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
  // {
  //   accessorKey: 'product_provider',
  //   header: 'Наименование поставщика ',
  //   meta: {
  //     cellProps: { className: 'text-900' }
  //   }
  // },
  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'debt',
    header: 'Дебет',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'credit',
    header: 'Кредит',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'remainder',
    header: 'Остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояние',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface SettelementHistoryProps {
  selectedProviderId?: any;
}

const SettelementHistory = ({
  selectedProviderId
}: SettelementHistoryProps) => {
  const { data } = useQuery(
    ['GET_HISTORY_ID'],
    () => {
      if (selectedProviderId) {
        const res = providerService
          .getProviderSettlementById(selectedProviderId, {
            offset: 0,
            limit: 20
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!selectedProviderId }
  );

  const historyData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          currency: items?.currency?.name?.ru,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.ru,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const table = useAdvanceTable({
    data: historyData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default SettelementHistory;
