/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { useQuery } from 'react-query';

import PriceListFilter from './PriceListFilter';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PriceListCards from './PriceListCards';
import ComponentToPrint from './ComponentToPrint';
import Button from 'components/base/Button';
import reportInvoiceService from 'service/reportInvoice';
import { pageToOffset } from 'utils/pageToOffset';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';

export type PriceListType = {
  name: string;
  variation: string;
  barcode: string;
  original_barcode: string;
  barcode_user: string;
  unit: string;
  guid: string;
  currency_id: string;
  quantity: any;
  currency: string;
  price_sale: string;
  measure_unit: string;
  measure_unit_id: string;
  scale_code: string;
};

interface ModalPriceListProps {
  selectedInvoiceId?: any;
}

const pageStyle = `@page {
  margin: 0;
  padding: 0;
};
`;

const PurchaseByInvoiceModalPriceList = ({
  selectedInvoiceId
}: ModalPriceListProps) => {
  const CurrenyName = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency?.name?.ru
  );

  const componentRef = useRef<any>(null);

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceListDataTable, setPriceListDataTable] = useState<any>([]);
  const [activeCard, setActiveCard] = useState('card-58-30');
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [count, setCount] = useState('');
  const [hidePrice, setHidePrice] = useState(false);
  const [hideBarcode, setHideBarcode] = useState(false);
  const [updatedItems, setUpdatedItems] = useState<
    Record<string, PriceListType>
  >({});
  console.log('updatedItems', updatedItems);

  const { data, isFetching, isLoading } = useQuery(
    ['GET_PRODUCTS', selectedInvoiceId, currentPage],
    async () => {
      if (selectedInvoiceId) {
        const resData = await reportInvoiceService
          .getReportProduct({
            limit: 10,
            offset: pageToOffset(currentPage, 10),
            invoice_id: selectedInvoiceId
          })
          .then((res: any) => {
            return res;
          });
        return resData;
      }
    },
    { enabled: !!selectedInvoiceId || !!currentPage }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const updateQuantity = (
    barcode: string,
    name: string,
    currency: string,
    price_sale: string,
    measure_unit: string,
    scale_code: string,
    newValue: number
  ) => {
    setUpdatedItems(prev => ({
      ...prev,
      [barcode]: {
        ...prev[barcode],
        barcode,
        name,
        currency,
        price_sale,
        measure_unit,
        scale_code,
        quantity: newValue
      }
    }));
  };

  useEffect(() => {
    console.log('Data from useQuery:', data);
    if (Array.isArray(data?.products)) {
      const resData = data?.products?.map((items: any) => {
        const updatedItem = updatedItems[items.barcode];
        return {
          name: items?.name,
          currency: CurrenyName,
          barcode: items?.barcode,
          price_sale: items?.price ?? 0,
          measure_unit: items?.nomenclature?.measure_unit?.name?.ru,
          quantity: updatedItem?.quantity ?? 0,
          scale_code:
            items?.nomenclature?.measure_unit?.id ===
            '208baabf-b8dd-4e2e-b3a0-05c2f6744052'
              ? items?.scale_code ?? 0
              : ''

          // items?.nomenclature?.measure_unit?.id ===
          // '0830abaa-87e7-49cc-a3cf-f8a49834e34d'
          //   ? items?.quantity
          //   : 1
        };
      });

      if (resData) {
        setPriceListDataTable(resData);
      }
    } else {
      console.error('Data is not an array:', data);
    }
  }, [data, CurrenyName, updatedItems]);

  const priceListTableColumns: ColumnDef<PriceListType>[] = [
    {
      accessorKey: 'name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_sale',
      header: 'Цена продажи',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Количество',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { value } = e.target;
          updateQuantity(
            priceListDataTable[index].barcode,
            priceListDataTable[index].name,
            priceListDataTable[index].currency,
            priceListDataTable[index].price_sale,
            priceListDataTable[index].measure_unit,
            priceListDataTable[index].scale_code,
            +value
          );
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            // Allow empty string for intermediate state
            setValue(newValue);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { value } = e.target as HTMLInputElement;
            updateQuantity(
              priceListDataTable[rowData.row?.id].barcode,
              priceListDataTable[rowData.row?.id].name,
              priceListDataTable[rowData.row?.id].currency,
              priceListDataTable[rowData.row?.id].price_sale,
              priceListDataTable[rowData.row?.id].measure_unit,
              priceListDataTable[rowData.row?.id].scale_code,
              +value
            );
          }
        };
        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="quantity"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => {
                  if (value === '') {
                    setValue('1'); // Reset to default value if left empty
                  }
                  onBlur(e, rowData.row?.id);
                }}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                min="1"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: priceListDataTable,
    columns: priceListTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e);
  };

  const handleRowSelection = (items: any) => {
    const updatedSelectedRows: any[] = [];

    // Iterate over the items and push each item based on its quantity
    Object.values(items).forEach((item: any) => {
      // Push the item multiple times based on its quantity
      for (let i = 0; i < item.quantity; i++) {
        updatedSelectedRows.push({
          barcode: item.barcode,
          name: item.name,
          currency: item.currency,
          price_sale: item.price_sale,
          measure_unit: item.measure_unit,
          scale_code: item.scale_code,
          quantity: item.quantity // Keep the quantity same for each duplicate
        });
      }
    });

    setSelectedRows(updatedSelectedRows);
  };

  useEffect(() => {
    // Get all rows' data from the table
    const allRows = updatedItems;

    console.log('updatedItems', updatedItems);

    // Handle selection with all rows
    handleRowSelection(allRows);
  }, [updatedItems]);

  // const handleChange = () => {
  //   if (count === '') {
  //     return;
  //   }

  //   // Update quantity for all items in priceListDataTable
  //   const updatedPriceListDataTable = priceListDataTable.map(
  //     (item: PriceListType) => ({
  //       ...item,
  //       quantity: parseInt(count, 10) // Set new quantity from count input
  //     })
  //   );

  //   // Update the table data with the new quantities
  //   setPriceListDataTable(updatedPriceListDataTable);

  //   // Also update selectedRows to reflect new quantities
  //   const updatedSelectedRows = updatedPriceListDataTable.map(
  //     (item: PriceListType) => ({
  //       ...item
  //     })
  //   );

  //   setSelectedRows(updatedSelectedRows);
  // };

  const handleChange = () => {
    if (count === '') {
      return;
    }

    // Step 1: Update the quantity for all items in priceListDataTable
    const updatedPriceListDataTable = priceListDataTable.map(
      (item: PriceListType) => ({
        ...item,
        quantity: parseInt(count, 10) // Set new quantity from count input
      })
    );

    // Step 2: Update the priceListDataTable state with the new quantities
    setPriceListDataTable(updatedPriceListDataTable);

    // Step 3: Update the global `updatedItems` with the new quantities for all items
    const updatedGlobalItems: Record<string, PriceListType> = {};
    updatedPriceListDataTable.forEach((item: PriceListType) => {
      updatedGlobalItems[item.barcode] = {
        ...item,
        quantity: parseInt(count, 10) // Update the quantity for each item in updatedItems
      };
    });

    // Step 4: Set updatedItems with the new data
    setUpdatedItems(updatedGlobalItems);

    // Optional: Reflect these changes in selectedRows if needed
    setSelectedRows(updatedPriceListDataTable);
  };

  console.log('selectedRows', selectedRows);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <PriceListFilter handleSearchInputChange={handleSearchInputChange} />
        <div className="d-flex gap-2">
          <Form.Floating>
            <Form.Control
              type="number"
              placeholder="Количество"
              value={count}
              onChange={e => setCount(e?.target?.value)}
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">Количество</label>
          </Form.Floating>

          <Button
            // disabled={selectedRows?.length > 0 ? false : true}
            variant="primary"
            onClick={() => handleChange()}
          >
            Изменить
          </Button>
        </div>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n3 px-lg-4 border-top border-bottom border-300">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <div className="d-flex mt-3 justify-content-between">
        <PriceListCards
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          hidePrice={hidePrice}
          hideBarcode={hideBarcode}
        />

        <div className="w-25 d-flex flex-column">
          <Form.Group className="printing-check  mt-auto">
            <Form.Check
              type="switch"
              checked={hidePrice}
              onChange={e => setHidePrice(e?.target?.checked)}
              className="cursor-pointer"
              label="Скрыть цену"
            />
          </Form.Group>

          <Form.Group className="printing-check">
            <Form.Check
              type="switch"
              checked={hideBarcode}
              onChange={e => setHideBarcode(e?.target?.checked)}
              className="cursor-pointer"
              label="Скрыть штрих-код"
            />
          </Form.Group>
          <ReactToPrint
            trigger={() => (
              <Button
                disabled={selectedRows?.length > 0 ? false : true}
                style={{ height: '48px', marginTop: '20px' }}
                variant="primary"
              >
                Печать
              </Button>
            )}
            content={() => componentRef?.current}
            pageStyle={pageStyle}
          />
        </div>

        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            {selectedRows?.map((items: any) => (
              <ComponentToPrint
                key={items}
                activeCard={activeCard}
                name={items?.name}
                barcode={items?.barcode}
                price={items?.price_sale}
                hidePrice={hidePrice}
                hideBarcode={hideBarcode}
                scale_code={items?.scale_code}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseByInvoiceModalPriceList;
