/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faCalendarXmark,
  faArrowRotateRight
} from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { showAlert } from 'store/alert/alert.thunk';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import marriageGoodsService from 'service/marriageGoods';
import MarriageGoodsAddTable from './MarriageGoodsAddTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import UpdateMarriageGoodsListQuantity from './UpdateMarriageGoodsListQuantity';
import { pageToOffset } from 'utils/pageToOffset';
import UpdateReturnQuantity from './UpdateReturnQuantity';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type MarriageGoodsType = {
  name: string;
  barcode: string;
  quantity: string;
  price_in: string;
  guid: string;
};

export const marriageGoodsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Брак товары',
    active: true
  }
];

const MarriageGoods = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const [searchedValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState<MarriageGoodsType>();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openReturnUpdate, setOpenReturnUpdate] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_PRODUCT', searchedValue],
    async () => {
      const res = await marriageGoodsService
        .getList({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          search: searchedValue
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    { enabled: !!currentPage || !searchedValue || searchedValue?.length > 2 }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const marriageGoodsData: MarriageGoodsType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();
    return (
      data?.Defective_products?.map((items: any) => {
        return {
          barcode: items?.product?.barcode,
          name: items?.product?.name,
          quantity: items?.quantity,
          price_in: items?.product?.price_in,
          currency_id: items?.product?.currency_id,
          nomenclature_id: items?.product?.nomenclature_id,
          product_id: items?.product_id,
          invoice: items?.invoice_number,
          measure_unit_id: items?.product?.measure_unit_id,
          /* prettier-ignore */
          invoice_date:  formatDateToTimeZone(items?.invoice_date, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: any) => {
    marriageGoodsService?.delete(id)?.finally(() => {
      refetch();
    });
  };

  const handleWriteOff = (data: any) => {
    const createData = {
      currency_id: data?.currency_id,
      nomenclature_id: data?.nomenclature_id,
      price_in: data?.price_in,
      product_id: data?.product_id,
      quantity: data?.quantity,
      quantity_actual: 0,
      status_id: 'af0fe0e3-3357-4b29-a33f-138c89cb75db'
    };

    const updatedData = {
      id: data?.guid,
      inventory_items: [createData]
    };
    marriageGoodsService?.createWriteOff(updatedData).then((res: any) => {
      if (res) {
        dispatch(
          showAlert({
            title: 'Брак товар успешно списан!',
            type: 'success'
          })
        );
        refetch();
      }
    });
  };

  const marriageGoodsTableColumns: ColumnDef<MarriageGoodsType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>Изменить</Tooltip>}
          >
            <div>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  setSelectedItems(rowData?.row?.original);
                  setOpenUpdate(true);
                }}
              >
                <FeatherIcon
                  icon="edit-2"
                  className="cursor-pointer"
                  size={20}
                />
              </Button>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>Списывать</Tooltip>}
          >
            <div>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => handleWriteOff(rowData?.row?.original)}
              >
                <FontAwesomeIcon icon={faCalendarXmark} className="fs-7" />
              </Button>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>Возврат</Tooltip>}
          >
            <div>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  setOpenReturnUpdate(true);
                  setSelectedItems(rowData?.row?.original);
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateRight} className="fs-7" />
              </Button>
            </div>
          </OverlayTrigger>

          <Button
            variant="hover"
            className="p-2"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Названия товара',
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Количество',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price_in',
      header: 'Цена закуп',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'invoice',
      header: '№ Накладной',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'invoice_date',
      header: 'Дата Накладной',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: marriageGoodsData,
    columns: marriageGoodsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const formatPrice = (price: any) => {
    const priceNumber = Number(price);
    if (isNaN(priceNumber)) {
      return { main: '0', decimals: '00' };
    }
    const priceParts = Number.isInteger(priceNumber)
      ? [priceNumber?.toString(), '00']
      : priceNumber?.toFixed(4).split('.');
    return {
      main: priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      decimals: priceParts[1]
    };
  };

  return (
    <div>
      <PageBreadcrumb items={marriageGoodsBreadcrumbItems} />

      <h2 className="mb-5">Брак товары</h2>

      <div className="d-flex gap-2 justify-content-between">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Выведите товар"
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Выведите товар
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Button
          type="button"
          variant="primary"
          onClick={() => setOpenSearch(true)}
          style={{ height: '48px' }}
        >
          Добавить товар
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <div className="purchase-footer-info">
        <div className="box">
          <div className="d-flex gap-2 my-auto">
            <p className="mb-0" style={{ fontWeight: '800' }}>
              Итого количества:
            </p>
            <span style={{ fontWeight: '800' }}>{data?.total_quantity}</span>
          </div>
        </div>
        <div className="box">
          <p className="mb-0" style={{ fontWeight: '800' }}>
            Итого сумма поступлений
          </p>

          {data?.total_amount?.map((amount: any) => {
            const { main, decimals } = formatPrice(amount?.amount);
            return (
              <div
                key={amount.currency_id}
                className="d-flex justify-content-between gap-2"
              >
                <span>{amount?.currency.name.ru}:</span>
                <span style={{ fontWeight: '800' }}>
                  {main}.{decimals}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        show={openSearch}
        onHide={() => {
          setOpenSearch(false);
          refetch();
        }}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Поиск</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarriageGoodsAddTable />
        </Modal.Body>
      </Modal>

      <Modal
        show={openUpdate}
        onHide={() => setOpenUpdate(false)}
        size="sm"
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title>Изменить количество</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateMarriageGoodsListQuantity
            refetch={refetch}
            setOpenUpdate={setOpenUpdate}
            selectedItems={selectedItems}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={openReturnUpdate}
        onHide={() => setOpenReturnUpdate(false)}
        size="sm"
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title>Возврат количество</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateReturnQuantity
            refetch={refetch}
            setOpenReturnUpdate={setOpenReturnUpdate}
            selectedItems={selectedItems}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MarriageGoods;
