/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import Button from 'components/base/Button';
import countryService from 'service/country';
import languageService from 'service/languages';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import { showAlert } from 'store/alert/alert.thunk';

interface CreateCurrencyProps {
  setOpenCurrency: (show: boolean) => void;
  refetch?: any;
  selectedCurrencyId?: any;
  setSelectedCurrencyId?: any;
}

const options = [
  {
    value: true,
    label: 'Активный'
  },
  {
    value: false,
    label: 'Заблокирован'
  }
];

const mainOption = [
  {
    value: true,
    label: 'Да'
  },
  {
    value: false,
    label: 'Нет'
  }
];

const CreateCurrency = ({
  setOpenCurrency,
  refetch,
  selectedCurrencyId,
  setSelectedCurrencyId
}: CreateCurrencyProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);
  const [countryOption, setCountryOption] = useState([]);

  const { register, handleSubmit, control, reset } = useForm();
  const hasFetchedData = useRef(false);
  const inputRef = useRef<HTMLInputElement | any>();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.ru
      }));
      setCountryOption(options);
    });
  });

  //fetch with id
  const fetchData = () => {
    if (selectedCurrencyId === '') return setLoading(false);

    legalEntityCurrencyService
      .getById(selectedCurrencyId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res?.currency?.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.currency.name[lang];
        });

        const computed = {
          ...nameObj,
          code: res?.currency?.char_code,
          country: res?.currency?.country_id,
          currency_id: res?.currency_id,
          number_code: res?.currency?.num_code,
          banknot: res?.currency?.max_banknote,
          main_currency: res?.is_main ? res?.is_main : false,
          status: res?.status ? res?.status : false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedCurrencyId !== '') return update(data);
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      char_code: data?.code,
      name: nameObj,
      country_id: data?.country,
      currency_id: data?.currency_id,
      num_code: +data?.number_code,
      max_banknote: +data?.banknot,
      /* prettier-ignore */
      is_main:
      data?.main_currency === 'true' || data?.main_currency === true
          ? true
          : data?.main_currency === 'false' || data?.main_currency === false
            ? false
            : false,
      // prettier-ignore
      status:
        data?.status === 'true' || data?.status === true
          ? true
          : data?.status === 'false' || data?.status === false
            ? false
            : false,
      id: selectedCurrencyId
    };
    legalEntityCurrencyService
      .update(selectedCurrencyId, updateData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Валюта успешно обновлен!',
              type: 'success'
            })
          );
          refetch();
          setLoading(false);
          setOpenCurrency(false);
          setSelectedCurrencyId('');
        }
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current, langData]);

  useEffect(() => {
    if (selectedCurrencyId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedCurrencyId, countryOption]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {langData &&
          langData.map((item: any, index: number) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Controller
                  name={`name_${item.char_code.toLowerCase()}`}
                  control={control}
                  render={({ field }) => (
                    <Form.Floating className="md-4">
                      <Form.Control
                        {...field}
                        ref={index === 0 ? inputRef : undefined}
                        type="text"
                        placeholder={`Наименование валюты ${item.char_code}`}
                        onFocus={e => e.target.select()}
                      />
                      <label htmlFor="floatingInputCustom">
                        Наименование ВАЛЮТЫ ( {item.char_code} )
                      </label>
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Code"
                    onFocus={e => e.target.select()}
                  />
                  <label htmlFor="floatingInputCustom">Коде валюты</label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('number_code')}
                type="text"
                placeholder="code"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Цифровой код валюты</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('banknot')}
                type="text"
                placeholder="Banknot"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Максимальная купюра</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue="O'zbekiston Respublikasi"
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Страна</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="main_currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {mainOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Основная валюта</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Состояние</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          Изменить
        </Button>
      </Form>
    </>
  );
};

export default CreateCurrency;
