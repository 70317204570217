/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import inventoryService from 'service/inventory';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type InfoType = {
  inventory_name: string;
  inventory_number: string;
  date_inventory: string;
  inventory_user: string;
  inventory_warehouse: string;
  date_create_inventory: string;
  guid: string;
};

interface InfoTableProps {
  dateInventory?: any;
  inventoryValue?: any;
  optionWarehouseId?: any;
  optionStatusId?: any;
  barcodeValue?: any;
  setInfoValues?: any;
}

const InfoTable = ({
  dateInventory,
  inventoryValue,
  optionWarehouseId,
  optionStatusId,
  barcodeValue,
  setInfoValues
}: InfoTableProps) => {
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(`/inventory/info/info-result/${row?.original?.guid}`);
  };

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, refetch } = useQuery(
    ['GET_PROVIDER_CHECK'],
    async () => {
      const res = await inventoryService
        .getList({
          offset: 0,
          limit: 10,
          write_off: true,
          user_id: optionStatusId ? optionStatusId : undefined,
          warehouse_id: optionWarehouseId ? optionWarehouseId : undefined,
          date_inventory: dateInventory,
          inventory_number: inventoryValue,
          barcode: barcodeValue
        })
        .then((res: any) => res?.inventories);

      return res;
    },
    {
      enabled:
        !!dateInventory ||
        !!inventoryValue ||
        !!optionWarehouseId ||
        !!optionStatusId ||
        !!barcodeValue
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const updatedInfoData: InfoType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resData =
      data?.map((items: any) => {
        return {
          inventory_name: items?.user?.first_name
            ? `${items?.user?.first_name} ${items?.user?.last_name}`
            : '',
          /* prettier-ignore */
          date_inventory:  formatDateToTimeZone(items?.inventory_date, timeZone),
          inventory_warehouse: items?.ware_house?.name,
          inventory_number: items?.inventory_number,
          /* prettier-ignore */
          date_create_inventory:  formatDateToTimeZone(items?.date_create, timeZone),
          smart_mode: items?.smart_mode === true ? 'Да' : 'Нет',
          guid: items?.id
        };
      }) ?? [];

    if (resData) {
      setInfoValues(resData);
    }

    return resData;
  }, [data, dataSettings]);

  const infoDataTableColumns: ColumnDef<InfoType>[] = [
    {
      accessorKey: 'inventory_name',
      header: 'Материально-ответственного лицо',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_inventory',
      header: 'Дата накладной',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'inventory_number',
      header: '№ накладной',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'inventory_warehouse',
      header: 'Склад',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'smart_mode',
      header: 'Умный режим',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_create_inventory',
      header: 'Дата создания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: updatedInfoData,
    columns: infoDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateInventory, inventoryValue, optionWarehouseId, optionStatusId]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            navigateTo={navigateTo}
          />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default InfoTable;
