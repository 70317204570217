/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  can_update: string;
  guid: string;
};

interface PurchaseWithCustomerHistoryProps {
  selectedCustomerId?: any;
}

const PurchaseWithCustomerHistory = ({
  selectedCustomerId
}: PurchaseWithCustomerHistoryProps) => {
  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data } = useQuery(
    ['GET_HISTORY_ID'],
    () => {
      if (selectedCustomerId) {
        const res = customerEntityService
          .getCustomerSettlementById(selectedCustomerId, {
            offset: 0,
            limit: 20
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!selectedCustomerId }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const historyData: SettelementData[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.ru,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          can_update: items?.can_update,
          currency: items?.currency?.name?.ru,
          maturity_date:
            items?.maturity_date === 0 || !items?.maturity_date
              ? ''
              : formatDateToTimeZone(items?.maturity_date, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    // {
    //   id: 'action',
    //   cell: rowData => (
    //     <div>
    //       {rowData?.row?.original?.can_update ? (
    //         <Button
    //           variant="primary"
    //           onClick={() => handleAccept(rowData?.row?.original?.guid)}
    //         >
    //           Подтвердить
    //         </Button>
    //       ) : (
    //         ''
    //       )}
    //     </div>
    //   ),
    //   meta: {
    //     headerProps: { style: { width: '7%' } },
    //     cellProps: { className: 'text-center' }
    //   }
    // },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: 'Дебет',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: 'Кредит',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: 'Остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'maturity_date',
      header: 'Дата погашения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: historyData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default PurchaseWithCustomerHistory;
