/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import roleService from 'service/role';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export type RolesType = {
  name: string;
  guid: string;
};

export const rolesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Роли',
    active: true
  }
];

const roleTranslationMap: Record<string, string> = {
  Admin: 'Админ',
  Accountant: 'Бухгалтер',
  Merchandiser: 'Мерчендайзер',
  'Main Cashier': 'Главный кассир',
  Cachier: 'Кассир',
  Customer: 'Клиент'
};

const translateRoleName = (name: string): string =>
  roleTranslationMap[name] || name;

const Roles = () => {
  const navigate = useNavigate();
  const roleName = useSelector((state: any) => state?.auth?.user?.role?.name);
  const legalEntityType = useSelector(
    (state: any) => state?.auth?.legal_entity?.legal_entity_type
  );

  const navigateTo = (row: any) => {
    navigate(`/roles/roles-create/${row?.original?.guid}`);
  };

  const { data } = useQuery(
    ['GET_ROLES', legalEntityType],
    () => {
      const res = roleService
        .getList({ offset: 0, limit: 10, system_level: legalEntityType })
        .then((res: any) => res?.data?.roles);
      return res;
    },
    { enabled: !!legalEntityType }
  );

  const rolesData: RolesType[] = useMemo(() => {
    return (
      data
        ?.filter((item: any) => item?.name !== roleName)
        ?.map((items: any) => {
          return {
            name: translateRoleName(items?.name),
            guid: items?.id
          };
        }) ?? []
    );
  }, [data, roleName]);

  const rolesTableColumns: ColumnDef<RolesType>[] = [
    {
      accessorKey: 'name',
      header: 'Наименование роли',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: rolesData,
    columns: rolesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={rolesBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Роли</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <SearchBox
              placeholder="Поиск роли"
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              navigateTo={navigateTo}
            />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Roles;
