/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal, Offcanvas } from 'react-bootstrap';
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import * as XLSX from 'xlsx';

import Nomenclature from './nomenclature';
import SearchProduct from './search';
import Button from 'components/base/Button';
import ndsService from 'service/nds';
import legalEntitySettingsService from 'service/legalEntitySettings';
import productSearchService from 'service/productSearch';
import usePageSearchParams from 'utils/usePageSearchParams';
import legalEntityCurrencyRateLastService from 'service/legalEntityCurrencyRateLast';
import ModalPriceList from './modal-price-list';
import '../../styles.scss';

interface PrintingFilterProps {
  setModalCreate: (show: boolean) => void;
  setIsVatPayerValue: (show: boolean) => void;
  modalCreate?: any;
  isVatPayerValue?: any;
  ndsData?: any;
  setNdsData?: any;
  setNdsPrice?: any;
  ndsPrice?: any;
  setNdsPriceWholsale?: any;
  ndsPriceWholsale?: any;
  setProductInvoiceData?: any;
  productInvoiceData?: any;
  barcodeInputRef: any;
  newInvoiceId?: any;
  focusRef?: any;
  saleToMinus?: any;
  setSaleToMinus?: any;
  setIsForced?: any;
  setIsTabToWhosale?: any;
}

const useDebounce = (func: any, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const PrintingFilter = ({
  newInvoiceId,
  modalCreate,
  setModalCreate,
  isVatPayerValue,
  setIsVatPayerValue,
  ndsData,
  setNdsData,
  setNdsPrice,
  ndsPrice,
  setNdsPriceWholsale,
  ndsPriceWholsale,
  setProductInvoiceData,
  productInvoiceData,
  barcodeInputRef,
  focusRef,
  saleToMinus,
  setSaleToMinus,
  setIsForced,
  setIsTabToWhosale
}: PrintingFilterProps) => {
  const countryId = useSelector((state: any) => state?.auth?.user?.country_id);

  const [searchParams] = usePageSearchParams();
  const invoiceCurrencyId = searchParams?.get('currency_id');

  const { register, handleSubmit } = useForm();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [modalShow, setModalShow] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [isAutoTab, setIsAutoTab] = useState(false);
  const [lastCurrenciesData, setLastCurrenciesData] = useState<any>([]);
  const [modalPriceList, setModalPriceList] = useState(false);
  const [closeSearchTable, setCloseSearchTable] = useState(false);
  const [newProduct, setNewProduct] = useState(false);
  const [isForceQuantity, setIsForceQuantity] = useState(false);

  console.log('searchedValue', searchedValue);
  //Get Legal entity currency rate last
  useQuery(['GET_LAST_RATE'], async () => {
    await legalEntityCurrencyRateLastService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setLastCurrenciesData(res?.rates);
      });
  });

  //Get NDS
  const fetchData = useMemo(() => {
    return () => {
      ndsService
        .getList({ offset: 0, limit: 100, country_id: countryId })
        .then((res: any) => {
          isVatPayerValue && setNdsData(res?.vats[0].value ?? 0);
        });
    };
  }, [countryId]);

  const fetchDataLegalSettings = useMemo(() => {
    return () => {
      legalEntitySettingsService
        .getList({ offset: 0, limit: 100 })
        .then((res: any) => {
          setNdsPrice(res?.legal_entity_settings[0].extra_charge ?? 0);
          setNdsPriceWholsale(
            res?.legal_entity_settings[0].extra_charge_wholesale ?? 0
          );
          setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
          setSaleToMinus(
            res?.legal_entity_settings[0]?.sale_in_negative ?? false
          );
          setIsForced(res?.legal_entity_settings[0]?.is_forced ?? false);
          setIsTabToWhosale(
            res?.legal_entity_settings[0]?.is_tab_inputs_all ?? false
          );
          setIsForceQuantity(
            res?.legal_entity_settings[0]?.is_force_quantity ?? false
          );
        });
    };
  }, [countryId]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    console.log('data', data);
  };

  const { refetch: refetchInvoice } = useQuery(
    ['GET_PRODUCT_SEARCH'],
    async () => {
      const a = await productSearchService
        .getList({
          offset: 0,
          limit: 20,
          barcode: searchedValue || undefined,
          is_global: true
        })
        .then((res: any) => {
          return res;
        });

      return a;
    },
    {
      enabled: !!searchedValue
    }
  );

  const { data: invoiceWithId, refetch: refetchInvoiceById } = useQuery(
    ['GET_PRODUCT_LIST'],
    async () => {
      if (newInvoiceId !== '') {
        const b = await productSearchService
          .getListProduct({
            offset: 0,
            limit: 20,
            invoice_id: newInvoiceId || undefined
          })
          .then((res: any) => {
            //focus to  barcode input
            barcodeInputRef.current?.focus();

            if (!res?.products) {
              setProductInvoiceData([]);
            }

            return res;
          });
        return b ?? [];
      }
    },
    {
      enabled: !!newInvoiceId
    }
  );

  console.log('invoiceWithId', invoiceWithId);
  // const scrollToBottom = () => {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  // };

  const debouncedHandleGetProduct = useDebounce(async (e: any) => {
    if (e?.key === 'Enter') {
      const dataa = await refetchInvoice();

      const createdData = dataa?.data?.products?.map((items: any) => {
        const packageQuantity = items?.package_quantity || 1;
        const totalQuantity = String(items?.total_quantity ?? 0);

        let integerPartTotal = 0;
        let numeratorTotal = 0;
        let denominatorTotal = 1;

        if (totalQuantity?.includes('.')) {
          [numeratorTotal, denominatorTotal] = totalQuantity
            .split('.')
            .map(Number);
          integerPartTotal = numeratorTotal; // Whole number part
          denominatorTotal = +`0.${denominatorTotal}`; // Remainder of the division
        } else {
          integerPartTotal = +totalQuantity;
        }

        const multipleFraction = Math?.round(
          +denominatorTotal * packageQuantity
        );

        const totalQuantityDisplay = totalQuantity?.includes('.')
          ? `${integerPartTotal}/${multipleFraction}`
          : integerPartTotal;

        let packageAmount = items?.price_in;
        let cost = items?.price_whosale;
        let allCost = items?.price;

        // Check if the item's currency_id matches any currency in lastCurrenciesData
        if (items.currency_id === invoiceCurrencyId) {
          packageAmount = items?.price_in;
          cost = items?.price_whosale;
          allCost = items?.price;
        } else {
          if (Array.isArray(lastCurrenciesData)) {
            for (const currencyData of lastCurrenciesData) {
              if (
                currencyData?.from_currency_id === items?.currency_id &&
                currencyData?.to_currency_id === invoiceCurrencyId
              ) {
                // Apply the conversion rate
                packageAmount = (
                  items?.price_in * currencyData?.to_currency_rate
                ).toFixed(2);

                cost = (
                  items?.price_whosale * currencyData?.to_currency_rate
                ).toFixed(2);
                allCost = (
                  items?.price * currencyData?.to_currency_rate
                ).toFixed(2);
              }
            }
          } else {
            console.error(
              'lastCurrenciesData is not iterable',
              lastCurrenciesData
            );
          }
        }

        return {
          name:
            dataa?.data?.is_product === true
              ? items?.name
              : items?.nomenclature?.name,
          is_product: dataa?.data?.is_product ?? false,
          variation: items?.nomenclature?.is_variation ? 'ДА' : 'НЕТ',
          barcode_user:
            dataa?.data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode,
          barcode_original: items?.nomenclature?.barcode_original,
          weght_code: items?.scale_code,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_kind_id: items?.nomenclature?.measure_unit_kind?.id,
          measure_unit_id: items?.nomenclature?.measure_unit_id,
          // quantity: saleToMinus ? 0 : 1,
          quantity: 0,
          amount: items?.package_quantity ?? 0,
          update_price: items?.update_price ?? false,
          package_amount: packageAmount,
          cost: cost,
          all_cost: allCost,
          /* prettier-ignore */
          current_balance: items?.nomenclature?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'? totalQuantityDisplay: items?.total_quantity ? new Intl.NumberFormat('en-US', {minimumFractionDigits: 0,maximumFractionDigits: 4,useGrouping: true}).format(items?.total_quantity).replace(/,/g, ' '): 0,
          guid: items?.nomenclature?.id
        };
      });

      if (!dataa?.data || !createdData?.length) {
        setModalCreate(true);
        return;
      }

      if (productInvoiceData?.length > 0) {
        const foundProductIndex = productInvoiceData?.findIndex((item: any) => {
          return item?.barcode_user === searchedValue;
        });
        if (foundProductIndex !== -1) {
          const updatedProductInvoiceData = [...productInvoiceData];

          const quantityCheck =
            updatedProductInvoiceData[foundProductIndex].quantity || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (
            typeof quantityCheck === 'string' &&
            quantityCheck?.includes('/')
          ) {
            // eslint-disable-next-line
            [numerator, denominator] = quantityCheck?.split('/').map(Number);

            integerPart = numerator += 1; // Whole number part
            denominator = +denominator; // Remainder of the division
          } else {
            integerPart = +quantityCheck;
          }

          const quantityDisplay =
            typeof quantityCheck === 'string' && quantityCheck?.includes('/')
              ? `${integerPart}/${+denominator}`
              : (integerPart += 1);

          if (
            updatedProductInvoiceData[foundProductIndex]
              .measure_unit_kind_id === '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
          ) {
            updatedProductInvoiceData[foundProductIndex].quantity =
              quantityDisplay;
          } else {
            updatedProductInvoiceData[foundProductIndex].quantity =
              (+updatedProductInvoiceData[foundProductIndex].quantity || 0) + 1;
          }

          const [updatedItem] = updatedProductInvoiceData.splice(
            foundProductIndex,
            1
          );
          updatedProductInvoiceData.unshift(updatedItem);
          setProductInvoiceData(updatedProductInvoiceData);
          if (isAutoTab) {
            // when auto tab true focus to quantity
            setTimeout(() => {
              const firstProductIndex = 0;
              focusRef(`quantity_${firstProductIndex}`);
            }, 500);
          }
          setModalCreate(false);
          return;
        } else {
          setProductInvoiceData((prevData: any) => {
            const newData = [...createdData, ...prevData];
            // scrollToBottom();
            barcodeInputRef.current?.select();
            if (isAutoTab) {
              // when auto tab true focus to quantity
              setTimeout(() => {
                const firstProductIndex = 0;
                focusRef(`quantity_${firstProductIndex}`);
              }, 500);
            }
            return newData;
          });
        }
      } else {
        setProductInvoiceData((prevData: any) => {
          const newData = [...createdData, ...prevData];
          // scrollToBottom();
          barcodeInputRef.current?.select();
          if (isAutoTab) {
            // when auto tab true focus to quantity
            setTimeout(() => {
              const firstProductIndex = 0;
              focusRef(`quantity_${firstProductIndex}`);
            }, 500);
          }
          return newData;
        });
      }
    }
  }, 500);

  // Use debouncing with a 300ms delay
  // const debouncedHandleGetProduct = useDebounce(handleGetProduct, 500);

  const handleExportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        if (e?.target?.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          console.log('jsonData test', jsonData);

          // Start processing from the second row (index 1)
          const filteredData = jsonData
            .slice(1)
            .filter((row: any) =>
              row.some(
                (cell: any) =>
                  cell !== null && cell !== undefined && cell !== ''
              )
            );

          // Explicitly type the array and map
          const groupedDataArray: any[] = [];
          const groupedDataMap: { [key: string]: any } = {};

          filteredData.forEach((row: any) => {
            const barcodeUser = String(row[3]);
            if (!barcodeUser) return;

            const existingEntry = groupedDataMap[barcodeUser];

            if (!existingEntry) {
              // If no entry exists, create a new one
              const newEntry: any = {
                name: row[1] ?? '',
                barcode_user: barcodeUser,
                units: row[5],
                current_balance: 0,
                quantity: row[4] ?? 0,
                package_amount: row[10] ?? 0,
                cost: row[12] ?? 0,
                all_cost: row[11] ?? 0,
                amount: row[6] ?? 1,
                weght_code: row[7] ?? 0,
                variation: 'Нет',
                barcode_original: barcodeUser,
                update_price: false,
                articul: row[2] ?? '',
                ikpu: row[8] ?? 0,
                party_number: row[9] ?? 0,
                with_nds: row[13] ?? 'Нет',
                category: row[14],
                from_excel: true,
                category_id: row[14],
                measure_unit_id: row[5]
              };
              groupedDataArray.push(newEntry);
              groupedDataMap[barcodeUser] = newEntry;
            } else {
              // Check if the name or other properties differ
              const nameChanged = existingEntry.name !== (row[1] ?? '');
              const otherPropertiesDiffer =
                existingEntry.articul !== (row[2] ?? '') ||
                existingEntry.units !== row[5] ||
                existingEntry.package_amount !== (row[10] ?? 0) ||
                existingEntry.cost !== (row[12] ?? 0) ||
                existingEntry.all_cost !== (row[11] ?? 0) ||
                existingEntry.amount !== (row[6] ?? 1) ||
                existingEntry.weght_code !== (row[7] ?? 0) ||
                existingEntry.ikpu !== (row[8] ?? 0) ||
                existingEntry.party_number !== (row[9] ?? 0) ||
                existingEntry.with_nds !== (row[13] ?? 'Нет') ||
                existingEntry.category !== row[14];

              if (nameChanged || otherPropertiesDiffer) {
                // If any properties differ or the name changed, replace with the latest data
                const updatedEntry: any = {
                  name: row[1] ?? '',
                  barcode_user: barcodeUser,
                  units: row[5],
                  current_balance: existingEntry.current_balance, // Preserve the current balance
                  quantity: row[4] ?? 0,
                  package_amount: row[10] ?? 0,
                  cost: row[12] ?? 0,
                  all_cost: row[11] ?? 0,
                  amount: row[6] ?? 1,
                  weght_code: row[7] ?? 0,
                  variation: 'Нет',
                  barcode_original: barcodeUser,
                  update_price: false,
                  articul: row[2] ?? '',
                  ikpu: row[8] ?? 0,
                  party_number: row[9] ?? 0,
                  with_nds: row[13] ?? 'Нет',
                  category: row[14],
                  from_excel: true,
                  category_id: row[14],
                  measure_unit_id: row[5]
                };
                // Remove the old entry from the array
                const index = groupedDataArray.findIndex(
                  entry => entry.barcode_user === barcodeUser
                );
                if (index !== -1) {
                  groupedDataArray.splice(index, 1, updatedEntry);
                } else {
                  groupedDataArray.push(updatedEntry);
                }
                groupedDataMap[barcodeUser] = updatedEntry;
              } else {
                // If all other properties are the same, just sum the quantities
                existingEntry.quantity += row[4] ?? 0;
              }
            }
          });

          // Update the productInvoiceData state with the parsed Excel data
          setProductInvoiceData((prevData: any) =>
            prevData.concat(groupedDataArray)
          );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (countryId) {
      fetchData();
    }
  }, [countryId]);

  useEffect(() => {
    if (newInvoiceId) {
      fetchDataLegalSettings();
    }
  }, []);

  useEffect(() => {
    const fetchComingData = async () => {
      if (newInvoiceId) {
        try {
          const newData = await refetchInvoiceById();

          const createdData =
            newData &&
            newData?.data?.products?.map((items: any) => {
              const packageQuantity = items?.package_quantity || 1;
              const quantity = String(items?.quantity) || '0';
              const totalQuantity = String(items?.total_quantity) || '0';
              let integerPart = 0;
              let numerator = 0;
              let denominator = 1;

              let integerPartTotal = 0;
              let numeratorTotal = 0;
              let denominatorTotal = 1;

              if (quantity?.includes('.')) {
                [numerator, denominator] = quantity.split('.').map(Number);
                integerPart = numerator; // Whole number part
                denominator = +`0.${denominator}`; // Remainder of the division
              } else {
                integerPart = +quantity;
              }

              if (totalQuantity?.includes('.')) {
                [numeratorTotal, denominatorTotal] = totalQuantity
                  .split('.')
                  .map(Number);
                integerPartTotal = numeratorTotal; // Whole number part
                denominatorTotal = +`0.${denominatorTotal}`; // Remainder of the division
              } else {
                integerPartTotal = +totalQuantity;
              }

              const multipleFraction = Math?.round(
                +denominatorTotal * packageQuantity
              );

              const quantityDisplay = quantity?.includes('.')
                ? `${integerPart}/${Math.round(+denominator * packageQuantity)}`
                : integerPart;

              const totalQuantityDisplay = totalQuantity?.includes('.')
                ? `${integerPartTotal}/${multipleFraction}`
                : integerPartTotal;

              let packageAmount = items?.price_in;
              let cost = items?.price_whosale;
              let allCost = items?.price;

              // Check if the item's currency_id matches any currency in lastCurrenciesData
              if (items.currency_id === invoiceCurrencyId) {
                packageAmount = items?.price_in;
                cost = items?.price_whosale;
                allCost = items?.price;
              } else {
                for (const currencyData of lastCurrenciesData) {
                  if (
                    currencyData?.from_currency_id === items?.currency_id &&
                    currencyData?.to_currency_id === invoiceCurrencyId
                  ) {
                    // Apply the conversion rate
                    packageAmount = (
                      items?.price_in * currencyData?.to_currency_rate
                    ).toFixed(2);
                    cost = (
                      items?.price_whosale * currencyData?.to_currency_rate
                    ).toFixed(2);
                    allCost = (
                      items?.price * currencyData?.to_currency_rate
                    ).toFixed(2);
                  }
                }
              }

              return {
                name: items?.name,
                is_product: newData?.data?.is_product ?? false,
                variation: items?.nomenclature?.is_variation ? 'ДА' : 'НЕТ',
                barcode_user: items?.barcode,
                barcode_original: items?.nomenclature?.barcode_original,
                weght_code: items?.scale_code,
                units: items?.nomenclature?.measure_unit?.name?.ru,
                quantity:
                  items?.nomenclature?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? quantityDisplay
                    : items?.quantity ?? 0,
                update_price: items?.update_price ?? false,
                amount: items?.package_quantity ?? 1,
                package_amount: packageAmount,
                measure_unit_kind_id:
                  items?.nomenclature?.measure_unit_kind?.id,
                measure_unit_id: items?.nomenclature?.measure_unit_id,
                cost: cost,
                all_cost: allCost,
                /* prettier-ignore */
                current_balance:
                  items?.nomenclature?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? totalQuantityDisplay
                    : items?.total_quantity
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 4,
                          useGrouping: true
                        })
                          .format(items?.total_quantity)
                          .replace(/,/g, ' ')
                      : 0,
                guid: items?.nomenclature?.id
              };
            });

          console.log('createdData', createdData);

          setProductInvoiceData([...createdData, ...productInvoiceData]);
        } catch (error) {
          console.error('Error fetching invoice by ID:', error);
        }
      }
    };

    fetchComingData();
  }, []);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="rounded mt-3"
      >
        <div className="printing-rows">
          <Form.Floating className="md-4">
            <Form.Control
              {...register('markup')}
              type="number"
              placeholder="Наценка в %"
              value={ndsPrice}
              onChange={e => setNdsPrice(e?.target?.value)}
              onFocus={e => e.target.select()}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">Наценка в %</label>
          </Form.Floating>

          <Form.Floating className="md-4">
            <Form.Control
              {...register('wholesale')}
              type="number"
              placeholder="Наценка оптивых тов  %"
              value={ndsPriceWholsale}
              onChange={e => setNdsPriceWholsale(e?.target?.value)}
              onFocus={e => e.target.select()}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">Наценка оптивых тов %</label>
          </Form.Floating>

          <Form.Group className="printing-check">
            <Form.Check
              {...register('wholesale')}
              type="switch"
              checked={isVatPayerValue}
              onChange={() => setIsVatPayerValue(!isVatPayerValue)}
              label="Пременит НДС"
              className="cursor-pointer"
            />
          </Form.Group>

          <Form.Floating className="nds md-4">
            <Form.Control
              {...register('nds')}
              type="text"
              placeholder="НДС %"
              value={ndsData}
              onChange={e => setNdsData(e?.target?.value)}
              onFocus={e => e.target.select()}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">НДС %</label>
          </Form.Floating>

          <Button
            disabled={productInvoiceData?.length > 0 ? false : true}
            variant="primary"
            onClick={() => setModalPriceList(true)}
          >
            Ценник
          </Button>
        </div>

        <div className="d-flex gap-2 mt-4">
          <Form.Floating>
            <Form.Control
              {...register('barcode')}
              ref={barcodeInputRef}
              type="text"
              placeholder="Выведите или отсканируйте штрих-код"
              onChange={e => setSearchedValue(e?.target?.value)}
              autoComplete="off"
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  debouncedHandleGetProduct(e);
                }
              }}
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">
              Выведите или отсканируйте штрих-код
            </label>
          </Form.Floating>

          <Button
            variant="primary"
            onClick={() => {
              setModalCreate(true);
              setNewProduct(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Новый
          </Button>
          <Button variant="outline-primary" onClick={() => setModalShow(true)}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Товар
          </Button>

          <Button
            disabled={productInvoiceData?.length > 0 ? true : false}
            variant="outline-primary"
            onClick={handleExportClick}
          >
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            Импорт
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".xlsx, .xls" // Specify the file types you want to allow
          />
        </div>

        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false), setCloseSearchTable(true);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Выбор закупа
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SearchProduct
              setModalShow={setModalShow}
              productInvoiceData={productInvoiceData}
              setProductInvoiceData={setProductInvoiceData}
              focusRef={focusRef}
              isAutoTab={isAutoTab}
              saleToMinus={saleToMinus}
              invoiceCurrencyId={invoiceCurrencyId}
              lastCurrenciesData={lastCurrenciesData}
              closeSearchTable={closeSearchTable}
              setCloseSearchTable={setCloseSearchTable}
              isForceQuantity={isForceQuantity}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={modalPriceList}
          onHide={() => setModalPriceList(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Печать ценника
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModalPriceList productInvoiceData={productInvoiceData} />
          </Modal.Body>
        </Modal>

        <Offcanvas
          show={modalCreate}
          onHide={() => {
            setModalCreate(false);
            setNewProduct(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              Добавить номенклатуру
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nomenclature
              setModalCreate={setModalCreate}
              searchedValue={searchedValue}
              setProductInvoiceData={setProductInvoiceData}
              productInvoiceData={productInvoiceData}
              isAutoTab={isAutoTab}
              focusRef={focusRef}
              newProduct={newProduct}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </div>
  );
};

export default PrintingFilter;
