/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import returnItemService from 'service/returnItemService';

import { OutgoingReturningResult } from 'data/returning';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export const outgoingResultTableColumns: ColumnDef<OutgoingReturningResult>[] =
  [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: 'Цена закупа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost_change',
      header: 'Цена разницы',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: 'Кол.возврата',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: 'Итоговая цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'final_price',
      header: 'Итоговая закуп цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояния',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

const OutgoingReturningResultTable = () => {
  const { pathname } = useLocation();
  const returningId = pathname.split('/').pop();

  const { data } = useQuery(
    ['GET_ORDER_ITEM', returningId],
    async () => {
      if (returningId !== '') {
        const res = await returnItemService
          .getReturnItemOutById(returningId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!returningId }
  );

  const outgoingResultData: OutgoingReturningResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          can_update_order_item: items?.can_update,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product_short_name,
          barcode: items?.barcode,
          currency: items?.currency?.name?.ru,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ?? 0,
          status: items?.status?.name?.ru,
          cost: items?.price ? items?.price : '0',
          cost_change: items?.price_in ? items?.price_in : '0',
          quantity: items?.quantity_send ?? 0,
          /* prettier-ignore */
          final_price:
            items?.price_in && items?.quantity
              ? +items.price_in * +items.quantity
                : 0,
          /* prettier-ignore */
          total_price: items?.price && items?.quantity ? (+items.price * +items.quantity) : 0
        };
      }) ?? [];

    return resultData;
  }, [data]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Возвраты',
      url: '/returning/outgoing'
    },
    {
      label: 'Исходящий',
      url: '/returning/outgoing'
    },
    {
      label: '№ ЗАЯВКИ',
      active: true
    }
  ];

  const table = useAdvanceTable({
    data: outgoingResultData,
    columns: outgoingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = outgoingResultData.reduce((acc: any, item: any) => {
    const allCost = item?.total_price === undefined ? 0 : +item?.total_price;
    return acc + allCost;
  }, 0);
  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">№ ЗАЯВКИ</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        <div className="footer-returning">
          <div className="box">
            <p className="mb-0 text-bold">Итого на сумму</p>
            <span>{sum ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgoingReturningResultTable;
