/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import Button from 'components/base/Button';
import marriageGoodsService from 'service/marriageGoods';
import { showAlert } from 'store/alert/alert.thunk';

interface UpdateReturnQuantityProps {
  setOpenReturnUpdate: (show: boolean) => void;
  refetch?: any;
  selectedItems?: any;
}

const UpdateReturnQuantity = ({
  setOpenReturnUpdate,
  refetch,
  selectedItems
}: UpdateReturnQuantityProps) => {
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm();

  const inputRef = useRef<HTMLInputElement | any>();

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (+data?.quantity === 0) {
      dispatch(
        showAlert({
          title: 'Количество не может быть 0'
        })
      );
      return;
    }

    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const updatedData = {
      id: selectedItems?.guid,
      return: {
        cashbox_id: cashboxId
      },
      return_item: {
        quantity: +data?.quantity
      }
    };

    marriageGoodsService?.createReturn(updatedData).then(res => {
      if (res) {
        refetch();
        setOpenReturnUpdate(false);
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const handleQuantityChange = (e: any, field: any) => {
    let value = e.target.value;

    // Check if the measure_unit_id allows decimal values
    if (
      selectedItems?.measure_unit_id ===
        '208baabf-b8dd-4e2e-b3a0-05c2f6744052' ||
      selectedItems?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
    ) {
      // Replace comma with period for decimal numbers
      value = value.replace(',', '.');

      // Allow decimal numbers, including 1.2, 4.5, 0., etc.
      if (/^\d*\.?\d*$/.test(value)) {
        field.onChange(value); // Allow the user to input the value as a string

        // Only parse it as a float and enforce limits if it's a valid number
        if (value !== '' && value !== '.' && !isNaN(parseFloat(value))) {
          const numericValue = parseFloat(value);

          // If the numeric value exceeds the remainder, cap it
          if (numericValue > selectedItems?.quantity) {
            setValue('quantity', selectedItems.quantity);
          }
        }
      }
    } else {
      // Handle integer values for other measure_unit_id
      const numericValue = parseInt(value, 10);

      if (value === '') {
        field.onChange(value); // Allow clearing the input
      } else if (!isNaN(numericValue)) {
        // Ensure the value is between 1 and handledItems.remainder
        if (numericValue < 1) {
          setValue('quantity', 1);
        } else if (numericValue > selectedItems?.quantity) {
          setValue('quantity', selectedItems.quantity);
        } else {
          field.onChange(numericValue);
        }
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <Controller
              name={`quantity`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={`Количество`}
                    onFocus={e => e.target.select()}
                    onChange={e => handleQuantityChange(e, field)}
                    isInvalid={!!errors.quantity}
                  />
                  <label htmlFor="floatingInputCustom">Количество</label>
                  {errors.quantity && (
                    <Form.Control.Feedback type="invalid">
                      Обязательное поле
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          Добавить
        </Button>
      </Form>
    </div>
  );
};

export default UpdateReturnQuantity;
