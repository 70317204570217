/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import productSearchService from 'service/productSearch';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';
import legalEntitySettingsService from 'service/legalEntitySettings';

interface MyProductHistoryProps {
  selectedProductId?: any;
}

export type MyProductType = {
  employee: string;
  new_json: {
    name: string;
    partion_number: string;
    price: number;
    price_whosale: number;
    package_quantity: string;
  };
  old_json: {
    name: string;
    partion_number: string;
    price: number;
    price_whosale: number;
    package_quantity: string;
  };
  time: string;
};

const MyProductHistory = ({ selectedProductId }: MyProductHistoryProps) => {
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, isFetching, isLoading } = useQuery(
    ['GET_HISTORY', currentPage],
    () => {
      if (selectedProductId) {
        const res = productSearchService
          .getListProductHistory({
            limit: 10,
            offset: pageToOffset(currentPage, 10),
            barcode: selectedProductId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: !!selectedProductId || !!currentPage }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const historyData: MyProductType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();
    return (
      data?.history_products?.map((items: any) => {
        return {
          employee: `${items?.user?.first_name} ${items?.user?.last_name}`,
          new_json: items?.new_json,
          old_json: items?.old_json,
          time: formatDateToTimeZone(items?.date_create, timeZone)
        };
      }) ?? []
    );
  }, [data, dataSettings]);

  const myProductHistoryTableColumns: ColumnDef<MyProductType>[] = [
    {
      accessorKey: 'employee',
      header: 'Сотрудники',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'name',
      header: 'Наименование товара',
      cell: rowData => {
        return (
          <div>
            <p className="p-0 m-0 d-flex gap-2 align-items-center">
              {rowData?.row?.original?.old_json.name}
              <FeatherIcon
                style={{ color: '#8200BF' }}
                icon="arrow-right"
                size={18}
              />
              {rowData?.row?.original?.new_json.name}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'partion_number',
      header: 'Серийный номер',
      cell: rowData => {
        return (
          <div>
            <p className="p-0 m-0 d-flex gap-2 align-items-center">
              {rowData?.row?.original?.old_json?.partion_number === 'undefined'
                ? 0
                : rowData?.row?.original?.old_json?.partion_number ?? 0}
              <FeatherIcon
                style={{ color: '#8200BF' }}
                icon="arrow-right"
                size={18}
              />
              {rowData?.row?.original?.new_json?.partion_number === 'undefined'
                ? 0
                : rowData?.row?.original?.new_json?.partion_number ?? 0}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'package_quantity',
      header: 'Кол.Упак',
      cell: rowData => {
        return (
          <div>
            <p className="p-0 m-0 d-flex gap-2 align-items-center">
              {rowData?.row?.original?.old_json?.package_quantity ===
                'undefined' ||
              !rowData?.row?.original?.old_json?.package_quantity
                ? 0
                : Math.floor(
                    +rowData?.row?.original?.old_json?.package_quantity
                  )}
              <FeatherIcon
                style={{ color: '#8200BF' }}
                icon="arrow-right"
                size={18}
              />
              {rowData?.row?.original?.new_json?.package_quantity ===
                'undefined' ||
              !rowData?.row?.original?.new_json?.package_quantity
                ? 0
                : Math.floor(
                    +rowData?.row?.original?.new_json?.package_quantity
                  )}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'price_whosale',
      header: 'Оптовая цена',
      cell: rowData => {
        return (
          <div>
            <p className="p-0 m-0 d-flex gap-2 align-items-center">
              {Math.floor(rowData?.row?.original?.old_json?.price_whosale)}
              <FeatherIcon
                style={{ color: '#8200BF' }}
                icon="arrow-right"
                size={18}
              />
              {Math.floor(rowData?.row?.original?.new_json?.price_whosale)}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: 'Цена продажи',
      cell: rowData => {
        return (
          <div>
            <p className="p-0 m-0 d-flex gap-3 align-items-center">
              {Math.floor(rowData?.row?.original?.old_json?.price)}
              <FeatherIcon
                style={{ color: '#8200BF' }}
                icon="arrow-right"
                size={18}
              />
              {Math.floor(rowData?.row?.original?.new_json?.price)}
            </p>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'time',
      header: 'Время',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: historyData,
    columns: myProductHistoryTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          isLoading={loading}
        />
        <AdvanceTableCPagination
          count={pageCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </AdvanceTableProvider>
  );
};

export default MyProductHistory;
