/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import QrIcon from './QrIcon';
import AddBonusProduct from './add/AddBonusProduct';
import AddBonusModal from './AddBonusModal';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import bonusService from 'service/bonus';
import SupperModal from 'components/modals/SupperModal';
import legalEntitySettingsService from 'service/legalEntitySettings';

export const bonusesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Бонусы',
    active: true
  }
];

export type BonusType = {
  product_name: string;
  date_start: string;
  date_end: string;
  system_approved: string;
  status: string;
  guid: string;
  is_system_approved: boolean;
};

const Bonuses = () => {
  const navigate = useNavigate();

  const [openBonus, setOpenBonus] = useState(false);
  const [openAddBonus, setOpenAddBonus] = useState(false);
  const [selectedBonusId, setSelectedBonusId] = useState('');
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get BONUS
  const { data, refetch } = useQuery(['GET_BONUS'], () => {
    const res = bonusService
      .getList({ offset: 0, limit: 10 })
      .then((res: any) => res?.bonuses);
    return res;
  });

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const bonusData: BonusType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          product_name: items?.name,
          is_system_approved: items?.is_system_approved ?? false,
          /* prettier-ignore */
          date_start: formatDateToTimeZone(items?.date_from, timeZone),
          /* prettier-ignore */
          date_end: formatDateToTimeZone(items?.date_to, timeZone),
          system_approved: items?.is_system_approved ? 'Да' : 'Нет',
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const navigateTo = (row: any) => {
    if (!row?.original?.is_system_approved) {
      navigate(`/bonuses/bonus-add/${row?.original?.guid}`);
    }
  };

  const bonusDataTableColumns: ColumnDef<BonusType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex gap-2">
            {!rowData?.row?.original?.is_system_approved && (
              <Button
                variant="hover"
                className="p-1"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(
                    `/bonuses/bonus-add/${rowData?.row?.original?.guid}`
                  );
                }}
              >
                <QrIcon fill="#8200BF" width="24" height="24" />
              </Button>
            )}

            <Button
              variant="hover"
              className="p-2"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedBonusId(rowData?.row?.original?.guid);
                setOpenBonus(true);
              }}
            >
              <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
            </Button>

            {!rowData?.row?.original?.is_system_approved && (
              <Button
                variant="hover"
                className="p-2"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDelete(rowData?.row?.original?.guid);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  className="cursor-pointer text-danger"
                  size={20}
                />
              </Button>
            )}
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: 'Наименование бонуса',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_start',
      header: 'Дата начала',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_end',
      header: 'Дата окончания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'system_approved',
      header: 'Одобрено системой',
      cell: rowData => {
        return (
          <div className="d-flex justify-content-center">
            {rowData?.row?.original?.is_system_approved ? (
              <FeatherIcon
                icon="check-circle"
                className="cursor-pointer"
                color="#8200BF"
                size={20}
              />
            ) : (
              <FeatherIcon
                icon="x-circle"
                className="cursor-pointer"
                color="#cc1b00"
                size={20}
              />
            )}
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 text-center' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: bonusData,
    columns: bonusDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      bonusService.deleteBonus(selectedInvoiceId).finally(() => {
        refetch();
        setSelectedBonusId('');
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={bonusesBreadcrumbItems} />
      <div>
        <div className="d-flex justify-content-between">
          <h2>Бонусы</h2>
          <Button
            style={{ height: '48px' }}
            variant="primary"
            onClick={() => setOpenBonus(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Новый
          </Button>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              navigateTo={navigateTo}
            />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        {/* <BonusResultTable /> */}
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Бонусы"
        bodyText="Вы действительно хотите удалить бонуса?"
      />

      <Offcanvas
        show={openBonus}
        onHide={() => {
          setOpenBonus(false);
          setSelectedBonusId('');
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Новый
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddBonusModal
            setOpenBonus={setOpenBonus}
            selectedBonusId={selectedBonusId}
            refetch={refetch}
            setSelectedBonusId={setSelectedBonusId}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openAddBonus}
        onHide={() => setOpenAddBonus(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Добавить Бонус
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBonusProduct setOpenAddBonus={setOpenAddBonus} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Bonuses;
