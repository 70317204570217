/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { Form, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useEffect, useRef, useState } from 'react';

import Button from 'components/base/Button';
import inventoryItemService from 'service/inventoryItem';
import { showAlert } from 'store/alert/alert.thunk';

interface UpdateQuantityProps {
  setOpenAmount: (show: boolean) => void;
  selectedQuantity?: any;
  refetchInventoryItem?: any;
}

const UpdateQuantity = ({
  setOpenAmount,
  selectedQuantity,
  refetchInventoryItem
}: UpdateQuantityProps) => {
  const { register, handleSubmit, control } = useForm();
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const [updatedQuantityActualValue, setUpdatedQuantityActualValue] =
    useState('');

  const onSubmit: SubmitHandler<FieldValues> = data => {
    console.log('data', data);
    if (+selectedQuantity?.quantity + +updatedQuantityActualValue < 0) {
      dispatch(
        showAlert({
          title: 'Количества не должен быть меньше нуля'
        })
      );
    }
    if (
      updatedQuantityActualValue !== '' &&
      selectedQuantity.quantity !== '' &&
      +selectedQuantity?.quantity + +updatedQuantityActualValue >= 0
    ) {
      inventoryItemService
        .updateInventoryItem(selectedQuantity?.id, {
          quantity_actual:
            +selectedQuantity?.quantity + +updatedQuantityActualValue
        })
        .then(res => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Количество успешно обновлён!',
                type: 'success'
              })
            );
            setOpenAmount(false);
            refetchInventoryItem();
          }
        });
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^-?\d*\.?\d*$/.test(newValue)) {
      setUpdatedQuantityActualValue(newValue);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);
  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                disabled
                defaultValue={selectedQuantity?.quantity}
                {...register('amount')}
                type="text"
                placeholder="Текущий количество"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Текущий количество</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Controller
              name="quantity_actual"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    value={updatedQuantityActualValue}
                    onChange={handleQuantityChange}
                    placeholder="Новое количество"
                    onFocus={e => e.target.select()}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">Новое количество</label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          className="w-100"
          variant="primary"
          type="submit"
        >
          Применить
        </Button>
      </Form>
    </>
  );
};

export default UpdateQuantity;
