/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Offcanvas, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { pageToOffset } from 'utils/pageToOffset';
import cashboxService from 'service/cashbox';
import PrintCheck from './PrintCheck';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import documentStatusService from 'service/documentStatus';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type checkType = {
  check_number: string;
  cashier: string;
  date_create: string;
  date_sync: string;
  status: string;
  currency: string;
  check_sum: string;
  guid: string;
};

export const cashInfoBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Отчеты',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'Касса',
    url: '/reports/cash-info'
  },
  {
    label: 'Чеки',
    active: true
  }
];

const Checks = () => {
  const { pathname } = useLocation();
  const cashboxId = pathname.split('/').pop();

  const { control, setValue } = useForm();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCheckId, setSelectedCheckId] = useState('');
  const [openCheck, setOpenCheck] = useState(false);
  const [currencyId, setCurrencyId] = useState('');
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [amountFrom, setAmountFrom] = useState('');
  const [amountTo, setAmountTo] = useState('');
  const [statusId, setStatusId] = useState('');
  const [statusOption, setStatusOption] = useState<any>([]);

  console.log('statusId,statusId', statusId);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.ru
        }));
        setCurrencyOption(options);
      });
  });

  //GET Currency Entity to options
  useQuery(['GET_DOCUMENT_STATUS'], async () => {
    await documentStatusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.document_statuses?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.ru
        }));
        setStatusOption(options);
      });
  });

  //Get Product Search
  const { data, isFetching, isLoading } = useQuery(
    ['GET_CHECK', currentPage, currencyId, amountFrom, amountTo, statusId],
    async () => {
      if (cashboxId) {
        const res = await cashboxService
          .getReceipt({
            limit: 10,
            offset: pageToOffset(currentPage, 10),
            cashbox_id: cashboxId,
            currency_id: currencyId,
            amount_from: +amountFrom,
            amount_to: +amountTo,
            status_id: statusId
          })
          .then((res: any) => {
            return res;
          });
        return res;
      }
    },
    {
      enabled:
        !!currentPage ||
        !!cashboxId ||
        !!currencyId ||
        !!amountFrom ||
        !!amountTo ||
        !!statusId
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const checkData: checkType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.receipts?.map((items: any) => {
        return {
          check_number: items?.receipt_number,
          currency: items?.currency?.name?.ru,
          check_sum: items?.amount,
          cashier: `${items?.user?.first_name} ${items?.user?.last_name}`,
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          date_sync: formatDateToTimeZone(items?.date_sync, timeZone),
          status: items?.status?.name?.ru,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings]);

  const navigateTo = (row: any) => {
    setOpenCheck(true);
    setSelectedCheckId(row?.original?.guid);
  };

  const checkTableColumns: ColumnDef<checkType>[] = [
    {
      accessorKey: 'order_number',
      enableSorting: false,
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1 + (currentPage - 1) * 10;
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'check_number',
      header: '№ чека',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cashier',
      header: 'Кассир',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: 'Дата чека',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_sync',
      header: 'Дата синхронизации',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'check_sum',
      header: 'Сумма покупки',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Состояние',
      cell: rowData => {
        return (
          /* prettier-ignore */
          <div
            style={{
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color:
                rowData?.row?.original?.status === 'Продан'
                  ? '#119c2b'
                  : rowData?.row?.original?.status === 'Частична возврашен'
                    ? '#f0cc03'
                    : '#f2271c',
              background:
                rowData?.row?.original?.status === 'Продан'
                  ? '#bbfbd0'
                  : rowData?.row?.original?.status === 'Частична возврашен'
                    ? '#fbf8e4'
                    : '#fddfdd'
            }}
          >
            {rowData?.row?.original?.status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: checkData,
    columns: checkTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (currencyOption?.length === 1) {
      setValue('currency', currencyOption[0].value);
      setCurrencyId(currencyOption[0].value);
    }
  }, [currencyOption, setValue, setCurrencyId]);

  return (
    <div>
      <PageBreadcrumb items={cashInfoBreadcrumbItems} />

      <h2 className="mb-5">Чеки</h2>

      <div className="d-flex gap-2">
        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = currencyOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      console.log('selectedOption', selectedOption);
                      if (selectedOption) {
                        setCurrencyId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {currencyOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Валюта</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = statusOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setStatusId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {statusOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Состояние</label>
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Floating>
          <Form.Control
            type="text"
            placeholder="Сумма от"
            onFocus={e => e.target.select()}
            value={amountFrom}
            onChange={e => setAmountFrom(e?.target?.value)}
            autoComplete="off"
          />
          <label htmlFor="floatingInputCustom">Сумма от</label>
        </Form.Floating>

        <Form.Floating>
          <Form.Control
            type="text"
            placeholder="Сумма до"
            onFocus={e => e.target.select()}
            value={amountTo}
            onChange={e => setAmountTo(e?.target?.value)}
            autoComplete="off"
          />
          <label htmlFor="floatingInputCustom">Сумма до</label>
        </Form.Floating>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 px-4 mx-lg-n2 px-lg-4 bg-white border-top border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
            navigateTo={navigateTo}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openCheck}
        onHide={() => {
          setOpenCheck(false);
          setSelectedCheckId('');
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Печать чека
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: 0 }}>
          <PrintCheck selectedCheckId={selectedCheckId} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Checks;
