/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import { Modal } from 'react-bootstrap';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import Button from 'components/base/Button';
import productSearchService from 'service/productSearch';
import legalEntitySettingsService from 'service/legalEntitySettings';
import UpdateChoousenItem from './UpdateChoousenItem';
import { pageToOffset } from 'utils/pageToOffset';

export type SearchDataType = {
  id: string;
  product_name: string;
  product_id: string;
  barcode: string;
  units: string;
  total_price: string;
  remainder: string;
  date_order: string;
};

const MarriageGoodsAddTable = () => {
  const [searchValue, setSearchValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [openUpdateQuantity, setOpenUpdateQuantity] = useState(false);
  const [handledItems, setHandledItems] = useState<SearchDataType>();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: dataSettings,
    isFetching,
    isLoading
  } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, refetch } = useQuery(
    ['GET_PRODUCT_LIST', searchValue, getBarcode, currentPage],
    () => {
      const res = productSearchService
        .getListProduct({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          list: true,
          name: searchValue,
          barcode: getBarcode
        })
        .then((res: any) => {
          return res;
        });

      return res;
    },
    {
      enabled:
        !searchValue || searchValue.length > 2 || !getBarcode || !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const searchData: SearchDataType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();
    return (
      data?.products?.map((items: any) => {
        return {
          product_name:
            data?.is_product === true ? items?.name : items?.nomenclature?.name,
          barcode:
            data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          total_price: items?.price_in,
          remainder: items?.quantity ?? 0,
          date_order: formatDateToTimeZone(items?.date_created, timeZone),
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          product_id: items?.id,
          nomenclature_id: items?.nomenclature?.id
        };
      }) ?? []
    );
  }, [data, searchValue, getBarcode, dataSettings]);

  const handleItem = (data: any) => {
    setHandledItems(data);
    setOpenUpdateQuantity(true);
  };

  const searchTableColumns: ColumnDef<SearchDataType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleItem(rowData.row.original)}
          >
            <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'total_price',
      header: 'Цена закуп',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: 'Остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_order',
      header: 'Дата закупа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <div className="mb-4 d-flex gap-2">
            <div className="form-icon-container">
              <Form.Floating>
                <Form.Control
                  type="text"
                  placeholder="Отсканируйте штрих-код"
                  onFocus={e => e.target.select()}
                  onChange={e => setGetBarcode(e.target.value)}
                  className="form-icon-input"
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInputCustom"
                  className="form-icon-label text-700"
                >
                  Отсканируйте штрих-код
                </label>
              </Form.Floating>
              <FontAwesomeIcon
                icon={faBarcode}
                className="text-900 fs-9 form-icon"
              />
            </div>
            <SearchBox
              placeholder="Выведите наименования товара для поиска"
              onChange={e => setSearchValue(e?.target?.value)}
            />
          </div>
          <AdvanceTable
            isLoading={loading}
            tableProps={{ className: 'phoenix-table fs-9' }}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <Modal
        show={openUpdateQuantity}
        onHide={() => setOpenUpdateQuantity(false)}
        size="sm"
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title>Добавить количество</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateChoousenItem
            refetch={refetch}
            setOpenUpdateQuantity={setOpenUpdateQuantity}
            handledItems={handledItems}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MarriageGoodsAddTable;
